import { useContext, useEffect, useState } from "react";
import { TypographyH3 } from "../ui/Typography";
import { ResourceTable } from "./ResourceTable";
import { ResourceFilter } from "./ResourceFilter";
import { Integration, QueryStatus, ResponseDocument } from "@/types/types";
import { FileUpload } from "./FileUpload";
import { DocumentStoreContext } from "@/contexts/DocumentContext";
import wait from "wait";
import { CustomAlert } from "../CustomAlert";
import { checkUserFlag } from "@/utils/utils";
import { MultiFileUpload } from "./MultiFileUpload";
import { POLL_FILES_DURATION } from "@/constants";
import { AppDispatch, RootState } from "@/store/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchIntegrations, fetchIntegrationStructures } from "../Integration/integrationSlice";

export function ResourcesPage({
    loading,
    errorFetching,
    errorUploading,
    resources,
    handleUploadFile,
    handleListFiles,
    sortDocumentsFn,
}: {
    loading: boolean;
    errorFetching: boolean;
    errorUploading: boolean;
    resources: ResponseDocument[];
    handleUploadFile: (file: File) => Promise<void>;
    handleListFiles: () => void;
    sortDocumentsFn: (a: ResponseDocument, b: ResponseDocument) => number
}) {
    const integrations = useSelector((state: RootState) => state.integration.integrations)

    const [resourceFilter, setResourceFilter] = useState<string[]>(['shared', 'private', 'desia library']);

    async function uploadFile(file: File) {
        handleUploadFile(file)
            .then(() => {
                pollUploadingFiles();
            })
    }

    async function pollUploadingFiles(force?: boolean) {
        let maxPolls = 10;
        let polls = 0;

        while (polls < maxPolls) {
            if (processingFiles.length === 0 && !force) {
                break;
            }

            handleListFiles();
            polls++;
            await wait(POLL_FILES_DURATION);
        }
    }

    const processingFiles = resources.filter(r => !r.document_is_ready_to_use && r.document_processing_status !== "failed");
    const failedFiles = resources.filter(r => r.document_processing_status === "failed");
    const isLegacyUpload = checkUserFlag("files: legacy upload");
    const activeIntegrations = integrations.data?.filter((v) => v.integration_is_enabled && v.integration_has_setup) || []

    const filteredResources = resources.filter((r) => {
        const sharedFilter = resourceFilter.includes('shared') && r.document_is_part_of_desia_library === false && r.document_source !== 'integration' && r.document_visibility === 'organization'
        const privateFilter = resourceFilter.includes('private') && r.document_is_part_of_desia_library === false && r.document_source !== 'integration' && r.document_visibility === 'private'
        const integrationFilter = resourceFilter.includes(r.document_source_details?.integration_code_name)
        const desiaLibraryFilter = resourceFilter.includes('desia library') && r.document_is_part_of_desia_library === true
        return sharedFilter || privateFilter || integrationFilter || desiaLibraryFilter
    })
    const sortedResources = [...filteredResources].sort(sortDocumentsFn);

    useEffect(() => {
        handleListFiles();

        const filters = localStorage.getItem('library_filters')
        if (filters) {
            setResourceFilter(JSON.parse(filters))
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('library_filters', JSON.stringify(resourceFilter))
    }, [resourceFilter])

    const handleFilterChange = (filterName: string) => {
        if (resourceFilter.includes(filterName)) {
            setResourceFilter(resourceFilter.filter((v) => v !== filterName))
        } else {
            setResourceFilter([...resourceFilter, filterName])
        }
    }

    return (
        <div className="max-w-full mt-10 mobile:mt-0 sm:max-w-[956px] mx-auto flex flex-col gap-[72px]">
            <div className="flex flex-col gap-8">
                <div className="text-center">
                    <TypographyH3>Library</TypographyH3>
                </div>

                {!isLegacyUpload ? <MultiFileUpload /> : <FileUpload handleUploadFile={uploadFile} />}
            </div >

            {errorUploading && (
                <CustomAlert
                    variant="error"
                    title="We could not process all the files"
                    description="We failed to upload that file, please try again shortly"
                />
            )}

            {failedFiles.length > 0 && (
                <div className="w-full sm:w-fit mx-auto">
                    <CustomAlert
                        variant="error"
                        title="We could not process all the files"
                        description="We have notified the team and they will be fixing them as soon as possible."
                    />
                </div>
            )}

            <div className="flex flex-col gap-6">
                <ResourceFilter
                    integrations={activeIntegrations}
                    selectedFilter={resourceFilter}
                    handleFilterChange={handleFilterChange}
                />
                <ResourceTable documents={sortedResources} loading={loading} error={errorFetching} />
            </div>
        </div >
    )
}

export function ResourcesPageContainer({ sortDocumentsFn }: { sortDocumentsFn: (a: ResponseDocument, b: ResponseDocument) => number }) {
    const dispatch = useDispatch<AppDispatch>()

    const documentContext = useContext(DocumentStoreContext);
    const loading = documentContext.store.status === QueryStatus.FETCHING;
    const errorFetching = documentContext.store.status === QueryStatus.ERROR_FETCHING;
    const errorUploading = documentContext.store.status === QueryStatus.ERROR_UPLOADING;
    const resources = documentContext.store.data;
    const handleUploadFile = documentContext.handlers.uploadDocument;
    const handleListFiles = documentContext.handlers.listDocuments;

    useEffect(() => {
        dispatch(fetchIntegrations())
            .then((action) => {
                const payload = action.payload as Integration[]

                payload.forEach((v) => dispatch(fetchIntegrationStructures(v.integration_id)))
            })
    }, [])

    return (
        <ResourcesPage
            loading={loading}
            errorFetching={errorFetching}
            errorUploading={errorUploading}
            resources={resources || []}
            handleUploadFile={handleUploadFile}
            handleListFiles={handleListFiles}
            sortDocumentsFn={sortDocumentsFn}
        />
    )
}
