import { ArrowLeft, Loader2 } from "lucide-react";
import { Button } from "../ui/button";
import { FileTable } from "./FileTable"
import { FileStructure, Integration, RequestSynchronizationControl, ResponseIntegrationStructure } from "@/types/types";
import { useContext, useEffect, useState } from "react";
import { WarnOnAction } from "../WarnOnAction";
import { useLocation, useNavigate } from "react-router-dom";
import { TypographyBody, TypographyH4 } from "../ui/Typography";
import { getIntegrationName } from "@/utils/utils";
import { Dialog, DialogContent, DialogFooter, DialogTitle, DialogTrigger } from "../ui/dialog";
import { DialogClose, DialogDescription } from "@radix-ui/react-dialog";
import { useDispatch, useSelector } from "react-redux";
import { deleteIntegration, fetchIntegrationStructures, setCurrentPage, uploadSynchronizationControl, uploadSynchronizationDelete } from "./integrationSlice";
import { AppDispatch, RootState } from "@/store/store";
import { CustomAlert } from "../CustomAlert";
import { UserContext } from "@/contexts/UserContext";

export const IntegrationSelection = ({ integration, initialSelection }: { integration: Integration, initialSelection?: boolean }) => {
    const integrationStructure = useSelector((state: RootState) => state.integration.integrationStructure)

    const { user } = useContext(UserContext)

    const initialStructures = integrationStructure[integration.integration_id]?.data?.structures || []

    const [elements, setElements] = useState<FileStructure[]>(initialStructures)

    const rootElements = elements?.filter((v) => v.parent_element_internal_ids.length === 0) || []

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch<AppDispatch>()

    const isLoading = integrationStructure[integration.integration_id]?.status === 'loading'

    const getSelectedElements = (elements: FileStructure[]): number[] => {
        let includedElements = []

        includedElements = elements.filter((v) => {
            const parentId = v.parent_element_internal_ids.at(-1)
            const parent = elements.find((v) => v.internal_element_id === parentId)

            return v.is_included && v.element_type !== 'file' && !(parent?.is_included || false)
        }).map((v) => v.internal_element_id)

        return includedElements
    }

    const handleSubmit = () => {
        if (rootElements.every((v) => !v.child_is_included && !v.is_included) || !user) return

        const includedElements = getSelectedElements(elements)

        const payload: RequestSynchronizationControl = {
            auth: {
                "user_id": user?.sub,
                "organization_id": user?.app_metadata.organization_id,
                "user_email": user.email
            },
            "integration_id": integration.integration_id,
            "payload": [
                {
                    "action": "include",
                    "internal_element_ids": includedElements
                }
            ],
            "resync_all": false
        }

        dispatch(uploadSynchronizationControl(payload))
            .then((action) => {
                if (action.meta.requestStatus === 'fulfilled') {
                    navigate('/integrations')
                }
            })
    }

    const handleUpdate = () => {
        if (rootElements.every((v) => !v.child_is_included && !v.is_included) || !user) return

        const prevSelection = getSelectedElements(integrationStructure[integration.integration_id]?.data?.structures || [])
        const newSelection = getSelectedElements(elements)

        const elementsToRemove = prevSelection.filter((v) => !newSelection.includes(v))
        const elementsToAdd = newSelection.filter((v) => !prevSelection.includes(v))

        const promises: Promise<any>[] = [];

        if (elementsToRemove.length > 0) {
            promises.push(
                dispatch(uploadSynchronizationDelete({
                    integration_id: integration.integration_id,
                    internal_elements_ids: elementsToRemove
                }))
            )
        }

        if (elementsToAdd.length > 0) {
            const payload: RequestSynchronizationControl = {
                auth: {
                    "user_id": user?.sub,
                    "organization_id": user?.app_metadata.organization_id,
                    "user_email": user.email
                },
                "integration_id": integration.integration_id,
                "payload": [
                    {
                        "action": "include",
                        "internal_element_ids": elementsToAdd
                    },
                ],
                "resync_all": false
            }
            promises.push(
                dispatch(uploadSynchronizationControl(payload))
            )
        }

        if (promises.length > 0) {
            Promise.all(promises)
                .then(() => {
                    navigate('/integrations')
                })
        }
    }

    const handleDelete = () => {
        dispatch(deleteIntegration(integration.integration_id))
            .then((action) => {
                if (action.meta.requestStatus === 'fulfilled') {
                    navigate('/integrations')
                }
            })
    }

    useEffect(() => {
        dispatch(fetchIntegrationStructures(integration.integration_id))
            .then((action) => {
                const payload = action.payload as ResponseIntegrationStructure
                const structures = payload.structures
                if (initialSelection) {
                    const selectedElements = structures.map((v) => {
                        return { ...v, is_included: true, child_is_included: false, is_excluded: false, child_is_excluded: false }
                    })
                    setElements(selectedElements)
                } else {
                    setElements(structures)
                }
            })
    }, [])

    useEffect(() => {
        if (isLoading) {
            localStorage.setItem('url_lock', location.pathname)
        } else {
            localStorage.removeItem('url_lock')
        }
    }, [isLoading])

    if (isLoading) {
        return (
            <div className="flex flex-col gap-6">
                <Loader2 className="w-10 h-10 mx-auto animate-spin flex-shrink-0" />
                <div className="flex flex-col gap-1 text-center">
                    <TypographyBody isStrong={true}>
                        Loading files...
                    </TypographyBody>
                    <TypographyBody className="text-system-body whitespace-pre-wrap">
                        {`This should take up to a minute. \nFor higher volumes of files, this might take a bit longer.`}
                    </TypographyBody>
                </div>
            </div>
        )
    }

    return (
        <div className={`flex flex-col gap-6 h-full`}>
            {!initialSelection && (
                <TypographyH4 className="mx-auto">
                    Select which resources to share
                </TypographyH4>
            )}

            <div className="flex-grow w-[560px] flex flex-col gap-12">
                <FileTable elements={elements} setElements={setElements} showCheckbox={true} />

                {rootElements.every((v) => !v.child_is_included && !v.is_included) && (
                    <CustomAlert
                        className="max-w-[315px] mx-auto"
                        title="No files have been selected"
                        description="Please make a selection to continue"
                        variant="error"
                    />
                )}
            </div>

            {initialSelection && (
                <div className="flex gap-2 mx-auto">
                    <WarnOnAction action={() => dispatch(setCurrentPage('authentication'))}>
                        <Button variant='secondary' >
                            <div className="flex gap-2">
                                <ArrowLeft className="h-6 w-6 shrink-0 stroke-[1.5px]" />

                                Back
                            </div>
                        </Button>
                    </WarnOnAction>


                    <Button onClick={() => handleSubmit()}>
                        Finish and connect
                    </Button>
                </div>
            )}

            {!initialSelection && (
                <>
                    {elements !== initialStructures && (
                        <div className="mx-auto">
                            <Button onClick={() => handleUpdate()}>
                                Confirm changes
                            </Button>
                        </div>
                    )}

                    {/* todo: replace with divider component */}
                    <div className="h-[1px] w-full bg-system-border-light my-6"></div>

                    <div className="flex flex-col gap-6">
                        <TypographyH4 className="mx-auto">
                            Remove integration
                        </TypographyH4>

                        <Dialog>
                            <DialogTrigger>
                                <Button variant='secondary-destructive' className="mx-auto">
                                    {`Remove ${getIntegrationName(integration.integration_code_name)} integration and all files`}
                                </Button>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogTitle>
                                    {`You are about to remove the ${getIntegrationName(integration.integration_code_name)} integration`}
                                </DialogTitle>
                                <DialogDescription className="pb-6 text-system-body">
                                    This will delete all the related files from the Desia platform
                                </DialogDescription>
                                <DialogFooter>
                                    <DialogClose>
                                        <Button variant='secondary-destructive' onClick={() => handleDelete()}>
                                            Remove integration and all files
                                        </Button>
                                    </DialogClose>
                                    <DialogClose>
                                        <Button>
                                            Keep integration
                                        </Button>
                                    </DialogClose>
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>
                    </div>
                </>
            )
            }

        </div >
    )
}