import { DedupedSearchQueryItem } from "@/types/types";
import { Skeleton } from "../ui/skeleton";
import { SearchResult } from "./SearchResult";
import { ErrorMessage } from "../ErrorMessage";
import { NotAvailableDialog } from "./NotAvailableDialog";
import { useState } from "react";
import { TypographyBody } from "../ui/Typography";

export function SearchResults({ error, loading, data }: { error: any, loading: boolean, data: DedupedSearchQueryItem[] }) {
    const [showDialog, setShowDialog] = useState(false)

    const handleAnalyse = () => {
        setShowDialog(true)
    }

    if (loading) {
        return (
            <div className="flex gap-4 sm:gap-6 mx-auto w-full">
                <div className="flex flex-col gap-4 sm:gap-6 w-full">
                    <Skeleton className="h-60 w-full" />
                    <Skeleton className="h-60 w-full" />
                    <Skeleton className="h-60 w-full" />
                </div>

                <div className="flex flex-col gap-4 sm:gap-6 w-full">
                    <Skeleton className="h-60 w-full" />
                    <Skeleton className="h-60 w-full" />
                    <Skeleton className="h-60 w-full" />
                </div>
            </div>
        )
    }
    if (error) {
        return (
            <ErrorMessage message="We failed to search for resources, please try again shortly" />
        )
    }

    const leftColumnElements = data.filter((_, index) => index % 2 === 0)
    const rightColumnElements = data.filter((_, index) => index % 2 === 1)

    return (
        <>
            {data.length === 0 && (
                <TypographyBody className="text-system-body text-center whitespace-pre-wrap">
                    {`We could not find any results.\nTry again with different keywords or check your spelling.`}
                </TypographyBody>
            )}

            <div className="hidden mobile:!flex gap-6 mx-auto w-full">
                <div className="flex flex-col gap-4 sm:gap-6 w-full mobile:max-w-[calc(50vw-32px-12px)] tablet:max-w-[calc(50%-16px)]">
                    {leftColumnElements.map((resource) => {
                        return (
                            <SearchResult resource={resource} key={resource.id} onAnalyseClick={handleAnalyse} />
                        )
                    })}
                </div>

                <div className="flex flex-col gap-4 sm:gap-6 w-full mobile:max-w-[calc(50vw-32px-12px)] tablet:max-w-[calc(50%-16px)]">
                    {rightColumnElements.map((resource) => {
                        return (
                            <SearchResult resource={resource} key={resource.id} onAnalyseClick={handleAnalyse} />
                        )
                    })}
                </div>
            </div>

            <div className="flex mobile:!hidden gap-4 mx-auto w-full max-w-[calc(100vw-32px)]">
                <div className="flex flex-col gap-4 w-full">
                    {data.map((resource) => {
                        return (
                            <SearchResult resource={resource} key={resource.id} onAnalyseClick={handleAnalyse} />
                        )
                    })}
                </div>
            </div>

            <NotAvailableDialog
                open={showDialog}
                setOpen={(v) => setShowDialog(v)}
            />
        </>
    )
}
