import { Search, X } from "lucide-react"
import { useState } from "react"

export type SearchbarType = 'navbar' | 'sidebar' | 'search-result'

export const Searchbar = ({ type, initialQuery, autoFocus, handleSearch }: { type: SearchbarType, initialQuery?: string, autoFocus?: boolean, handleSearch: (query: string) => void }) => {
    const [searchQuery, setSearchQuery] = useState(initialQuery || '')

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch(searchQuery);

            if (type !== 'search-result') {
                resetSearch()
            }
        }
    }

    const resetSearch = () => {
        setSearchQuery('')
    }

    const CloseButton = () => (
        <X className="w-5 h-5 shrink-0 stroke-[1.5px] absolute right-3 top-2 cursor-pointer" onClick={() => resetSearch()} />
    )

    if (type === 'sidebar') return (
        <div className="relative flex gap-2 flex-grow max-w-[480px] py-2 px-3 rounded-full border border-system-border-light bg-system-hover">
            <Search className="w-5 h-5 shrink-0 stroke-[1.5px]" />
            <input
                placeholder="Search across library"
                className="font-label placeholder:text-system-placeholder bg-transparent w-full focus:outline-none mr-7"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus={autoFocus}
            />
            {searchQuery && (
                <CloseButton />
            )}
        </div>
    )

    const style = `relative flex gap-2 flex-grow max-w-[480px] py-2 px-3 rounded-full border border-system-border-regular ${type === 'search-result' ? 'bg-system-secondary' : 'bg-system-surface-light'}`

    return (
        <div className={style}>
            <Search className="w-5 h-5 shrink-0 stroke-[1.5px]" />
            <input
                placeholder="Search across library (files, folders,...)"
                className="font-label hidden [@media(min-width:1170px)]:!flex placeholder:text-system-placeholder bg-transparent w-full focus:outline-none mr-7"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus={autoFocus}
            />
            <input
                placeholder="Search across library"
                className="font-label flex [@media(min-width:1170px)]:!hidden placeholder:text-system-placeholder bg-transparent w-full focus:outline-none mr-7"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus={autoFocus}
            />
            {searchQuery && (
                <CloseButton />
            )}
        </div>
    )
}